import { useEffect, useState } from 'react';
import { useAuthStorage } from '@data';
import { useShareMailboxContext, useToastsContext } from '@utils';
import { getIsNAASupported, getUserEmail } from '@utils-global';
import { isSafari } from '@constants';
import { fallbackLogin } from '../utils';

export const useFallbackAuthentication = () => {
  const { showToast } = useToastsContext();
  const [isSSOLoading, setSSOLoading] = useState(false);
  const { setAccessToken, setGraphToken } = useAuthStorage();
  const { isSharedMailbox, originalUserEmail } = useShareMailboxContext();
  // if it's shared mailbox we can't use getUserEmail since it'll return shared mailbox address
  const userEmail = isSharedMailbox ? originalUserEmail : getUserEmail();

  const loginWithDialogAPI = () => {
    fallbackLogin(
      {
        showToast,
        setAccessToken,
        setGraphToken,
        setSSOLoading,
        userEmail,
      },
      false,
    );
  };

  useEffect(() => {
    // firstly trying to authenticate user with SSO if it's not Safari and requirements set is supported
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issue-on-Safari
    if (!isSafari && !getIsNAASupported()) {
      console.log('Fallback SSO is supported and called!');

      if (['development', 'staging'].includes(process.env.TARGET_ENV)) {
        showToast('SSO');
      }

      fallbackLogin({ showToast, setAccessToken, setGraphToken, setSSOLoading, userEmail });
    }
  }, [isSafari]);

  return {
    isSSOLoading,
    loginWithDialogAPI,
  };
};
