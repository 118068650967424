import React from 'react';
import { useAuthStorage } from '@data';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@fluentui/react-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ChatPage, LoginPage, LogoutPage, NAALoginPage, ReportPage } from '@pages';
import { ErrorFallback, Header, PrivateComponent } from '@components';
import { paths } from '@constants';
import { useInitApp, SharedMailboxContextProvider } from '@utils';
import {} from './utils/providers/sharedMailboxProvider';

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
  },
});

const App = () => {
  const styles = useStyles();
  const { isI18NextInitialized } = useInitApp();
  const { accessToken, graphToken } = useAuthStorage();

  if (!isI18NextInitialized) return null;
  return (
    <SharedMailboxContextProvider>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <main className={styles.root}>
          <PrivateComponent>
            <Header />
          </PrivateComponent>
          <Switch>
            <Route exact path={`/${paths.report}`}>
              <PrivateComponent>
                <ReportPage />
              </PrivateComponent>
            </Route>
            <Route exact path={`/${paths.chat}`}>
              <PrivateComponent>
                <ChatPage />
              </PrivateComponent>
            </Route>
            <Route exact path={`/${paths.logout}`}>
              <PrivateComponent>
                <LogoutPage />
              </PrivateComponent>
            </Route>
            <Route exact path={`/${paths.login}`}>
              {/* <LoginPage /> */}
              <NAALoginPage />
            </Route>
            <Route exact path="/">
              <Redirect to={accessToken && graphToken ? `/${paths.login}` : `/${paths.report}`} />
            </Route>
          </Switch>
        </main>
      </Sentry.ErrorBoundary>
    </SharedMailboxContextProvider>
  );
};

export default App;
