/* eslint-disable camelcase */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { handleError } from '@utils-global';
import { useAuthStorage } from '@data';
import { SharedMailboxContext, SharedMailboxContextType } from '../contexts';

type Props = {
  children: ReactNode;
};

// Should be used after Office is initialized
export const SharedMailboxContextProvider = ({ children }: Props) => {
  const { accessToken, graphToken } = useAuthStorage();
  const [originalUserEmail, setOriginalUserEmail] = useState<string | null>(null);
  const [sharedProperties, setSharedProperties] = useState<Office.SharedProperties | null>(null);

  const isSharedMailbox = useMemo(
    () => !!Office.context.mailbox.item.getSharedPropertiesAsync,
    [!!Office.context.mailbox.item.getSharedPropertiesAsync],
  );

  const getSharedMailboxInfo = () => {
    Office.context.mailbox.item.getSharedPropertiesAsync((sharedPropertiesResponse) => {
      if (sharedPropertiesResponse.status === Office.AsyncResultStatus.Succeeded) {
        setSharedProperties(sharedPropertiesResponse.value);
      } else {
        handleError(sharedPropertiesResponse?.error);
      }
    });
  };

  useEffect(() => {
    try {
      if (isSharedMailbox && typeof accessToken === 'string' && typeof graphToken === 'string') {
        const { preferred_username } = jwtDecode<{ preferred_username?: string }>(accessToken);
        const { unique_name, upn } = jwtDecode<{ unique_name?: string; upn?: string }>(graphToken);

        const email = preferred_username || unique_name || upn;

        if (email) setOriginalUserEmail(email);
      }
    } catch (error) {
      handleError(error);
    }
  }, [accessToken, isSharedMailbox, graphToken]);

  useEffect(() => {
    if (isSharedMailbox) {
      getSharedMailboxInfo();
    }
  }, [isSharedMailbox]);

  const value: SharedMailboxContextType = useMemo(
    () => ({ isSharedMailbox, sharedProperties, originalUserEmail }),
    [isSharedMailbox, sharedProperties, originalUserEmail],
  );

  return <SharedMailboxContext.Provider value={value}>{children}</SharedMailboxContext.Provider>;
};
