import {
  IPublicClientApplication,
  RedirectRequest,
  createNestablePublicClientApplication,
} from '@azure/msal-browser';
import { handleError } from '@utils-global';

const getTokenRequest = (scopes: string[], selectAccount: boolean): RedirectRequest => {
  let additionalProperties: Partial<RedirectRequest> = {};

  if (selectAccount) {
    additionalProperties = { prompt: 'select_account' };
  }
  return { scopes, ...additionalProperties };
};

export class OfficeAccountManager {
  private pca: IPublicClientApplication;
  showToast: any;

  // Initialize MSAL public client application.
  async initialize() {
    this.pca = await createNestablePublicClientApplication({
      auth: {
        clientId: process.env.AZURE_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/common',
      },
    });
  }

  // @ts-ignore
  async ssoGetUserIdentity(scopes: string[]) {
    if (this.pca === undefined) {
      throw new Error('OfficeAccountManager is not initialized!');
    }

    try {
      // Trying to acquire token silently first.
      const authResult = await this.pca.acquireTokenSilent(getTokenRequest(scopes, false));
      return authResult.accessToken;
    } catch (error) {
      handleError(error);
    }

    try {
      // Trying to acquire token interactively
      const selectAccount = !this.pca.getActiveAccount();

      const authResult = await this.pca.acquireTokenPopup(getTokenRequest(scopes, selectAccount));

      if (selectAccount) {
        this.pca.setActiveAccount(authResult.account);
      }

      return authResult.accessToken;
    } catch (error) {
      // Acquire token interactive failure.
      handleError(error);
    }
  }
}
