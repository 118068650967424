const regularScopes = [
  'openid',
  'profile',
  'User.Read',
  'Mail.Read',
  'Mail.ReadBasic',
  'Mail.ReadWrite',
];
const sharedMailboxScopes = ['Mail.Read.Shared', 'Mail.ReadBasic.Shared', 'Mail.ReadWrite.Shared'];

// https://github.com/OfficeDev/office-js/issues/4622
// https://github.com/OfficeDev/office-js/issues/4767
// Currently NAA fails with shared mailbox scopes, so we need to exclude them
export const getAuthenticationScopes = (includeSharedMailboxScopes = false) =>
  includeSharedMailboxScopes ? [...regularScopes, ...sharedMailboxScopes] : regularScopes;
