import { useAuthStorage } from '@data';
import { useEffect, useState } from 'react';
import { getAuthenticationScopes, getIsNAASupported, handleError } from '@utils-global';
import { useShareMailboxContext, useToastsContext } from '@utils';
import { OfficeAccountManager } from '../utils/naa';

export const useNAA = () => {
  const { showToast } = useToastsContext();
  const { isSharedMailbox } = useShareMailboxContext();
  const [isNAALoading, setNAALoading] = useState(false);
  const { setAccessToken, setGraphToken } = useAuthStorage();

  const authenticateWithNAA = async () => {
    try {
      setNAALoading(true);
      const accountManager = new OfficeAccountManager();

      await accountManager.initialize();
      const accessToken = await accountManager.ssoGetUserIdentity(
        getAuthenticationScopes(isSharedMailbox),
      );

      setAccessToken(accessToken);
      setGraphToken(accessToken);
    } catch (error) {
      handleError(error);
    } finally {
      setNAALoading(false);
    }
  };

  useEffect(() => {
    if (getIsNAASupported()) {
      console.log('NAA is supported and called!');

      if (['development', 'staging'].includes(process.env.TARGET_ENV)) {
        showToast('NAA');
      }

      authenticateWithNAA();
    }
  }, []);

  return {
    isNAALoading,
  };
};
